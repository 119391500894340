import { graphql, useStaticQuery } from "gatsby";
import { SEOT } from "../Types/All";

//loads data directly from gatsby-config.ts > siteMetaData
export const useSiteMetadata = (): SEOT => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          keywords
          language
          image
          siteUrl
        }
      }
    }
  `);
  return data.site.siteMetadata;
};
